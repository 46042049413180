import React, {Component} from "react";

import HeaderBlock from "../../components/HeaderBlock";
import Header from "../../components/Header";
import Paragraph from "../../components/Paragraph";
import ContentBlock from "../../components/ContentBlock";
import CartList from "../../components/CardList";
import FooterBlock from "../../components/FooterBlock";
import {Form, Input, Button} from 'antd';
import FirebaseContext from "../../context/firebaseContext";
import s from './Home.module.scss'

class HomePage extends Component {
    state = {
        wordArr: [],
    }

    componentDidMount() {
        const {getUserCardsRef} = this.context;

        getUserCardsRef().on('value', res => {
            this.setState({
                wordArr: res.val() || []
            })
        })
    }

    handleSubmitButton = ({eng, rus}) => {
        const {getUserCardsRef} = this.context;
        const {wordArr} = this.state;

        getUserCardsRef().set([...wordArr, {
            id: +new Date(),
            eng,
            rus
        }]);
    }

    handleDeletedItem = (id) => {
        const {getUserCardsRef} = this.context;
        const {wordArr} = this.state;
        const newWordArr = wordArr.filter(item => item.id !== id)

        getUserCardsRef().set(newWordArr);
    }

    renderAddWordForm = () => {
        return (
            <div className={s.form_addword__wrap}>
                <Form.Provider
                    onFormFinish={(name, {values, forms}) => {
                        if (name === 'addWordForm') {
                            const {addWordForm} = forms;
                            addWordForm.resetFields();
                        }
                    }}
                >

                    <Form
                        size="large" name="addWordForm"
                        layout='inline'
                        initialValues={{layout: 'inline'}}
                        onFinish={this.handleSubmitButton}>

                        <Form.Item name='eng'>
                            <Input placeholder="English word"/>
                        </Form.Item>
                        <Form.Item name='rus'>
                            <Input placeholder="Russian word"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Добавить
                            </Button>
                        </Form.Item>
                    </Form>

                </Form.Provider>
            </div>
        )
    }

    render() {
        const {wordArr} = this.state;

        return (
            <>
                <HeaderBlock theme='light'>
                    <Header>
                        Время учить слова онлайн
                    </Header>

                    <Paragraph>
                        Используйте карточки для запоминания и пополняйте активный словарный запас.
                    </Paragraph>
                </HeaderBlock>

                <ContentBlock>
                    <Header color='black' textAlign='center' fontSize='size-36'>
                        Начать учить английский просто
                    </Header>

                    <Paragraph color='black' textAlign='center' fontSize='size-20'>
                        Кликай по карточкам и узнавай новые слова, быстро и легко!
                    </Paragraph>

                    {this.renderAddWordForm()}

                    <CartList onDeletedItem={this.handleDeletedItem} item={wordArr}/>

                </ContentBlock>

                <FooterBlock copyright="© 2020, Michael. First footer in default (dark) theme"/>
            </>
        )
    }
}

HomePage.contextType = FirebaseContext;

export default HomePage;