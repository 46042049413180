import React from "react";
import s from './FooterBlock.module.scss'

const FooterBlock = ({theme = 'dark', copyright}) => {
    return (
        <footer className={theme === 'light' ? s.light : s.dark}>
            <p>{copyright}</p>
        </footer>
    )
}

export default FooterBlock;