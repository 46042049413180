import React, {Component} from "react";
import Cart from "../Card";

import s from './CardList.module.scss';
import getTranslateWord from "../../services/dictionary";


class CartList extends Component {
    state = {
        value: '',
        label: '',
        isBusy: false,
    }

    handleInputChange = (e) => {
        this.setState({
            value: e.target.value
        })
    }

    getTheWord = async () => {
        const {value} = this.state;
        const getWord = await getTranslateWord(this.state.value);

        this.setState({
            value: '',
            label: `${value} - ${getWord.translate}`,
            isBusy: false
        })
    }

    render() {
        const {item = [], onDeletedItem} = this.props;
        const {label} = this.state;

        return (
            <>
                <div>{label}</div>

                <div className={s.root}>
                    {
                        item.map(({eng, rus, id}) => (
                            <Cart
                                onDeleted={() => {
                                    onDeletedItem(id);
                                }}
                                key={id}
                                eng={eng}
                                rus={rus}
                            />
                        ))
                    }

                </div>
            </>
        )
    }
}

export default CartList;