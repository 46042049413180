import React, {Component} from "react";

class AboutPage extends Component {

    render() {

        return (
            <>
                <h1>123Немного о себе...</h1>
            </>
        )
    }
}

export default AboutPage;