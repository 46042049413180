import React from 'react';
import s from './Card.module.scss';
import cl from 'classnames';
import {CheckSquareOutlined, DeleteOutlined} from '@ant-design/icons'

class Cart extends React.Component {

    state = {
        done: false,
        isRemembered: false,
    }

    /**
     * Toogle state.
     */
    handleCardClick = () => {
        this.setState(({done, isRemembered}) => {
            return {
                done: !isRemembered ? !done : true
            }
        });
    }

    /**
     * Remember click.
     */
    handleRememberClick = () => {
        this.setState({isRemembered: true, done: true});
    }

    handleDeleteClick = () => {
        console.log('#### level 1');
        this.props.onDeleted();
    }

    render() {
        const {eng, rus} = this.props;
        const {done, isRemembered} = this.state;

        return (
            <div className={s.root}>
                <div
                    className={cl(s.card, {
                        [s.done]: done,
                        [s.isRemembered]: isRemembered
                    })}
                    onClick={this.handleCardClick}
                >
                    <div className={s.cardInner}>
                        <div className={s.cardFront}>
                            {eng}
                        </div>
                        <div className={s.cardBack}>
                            {rus}
                        </div>
                    </div>

                </div>
                <div className={s.icons}>
                    <CheckSquareOutlined onClick={this.handleRememberClick}/>
                </div>

                <div className={s.icons}>
                    <DeleteOutlined onClick={this.handleDeleteClick} />
                </div>
            </div>
        );
    }
}

export default Cart;