import React from "react";
import s from './HeaderBlock.module.scss'

import bgImageHeader from './img/background.jpg';

const HeaderBlock = ({bgHide = false, bgColor = 'red', theme = 'dark', children}) => {

    const styleHeader =
        bgHide ? {backgroundColor: `${bgColor}`} : {backgroundImage: `url(${bgImageHeader})`};

    // Without module classname
    const classesHeader = theme === 'light' ? s.cover : [s.cover, s.dark].join(" ");

    return (
        <header className={classesHeader} style={styleHeader}>
            <div className={s.wrap}>
                {children}
            </div>
        </header>
    )
}

export default HeaderBlock;