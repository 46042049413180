import React from "react";
import s from "./ContentBlock.module.scss"

const ContentBlock = ({bgColor = 'white', children}) => {
    const styleContent = {
        backgroundColor: `${bgColor}`
    }

    return (
        <section style={styleContent} className={s.content}>
            {children}
        </section>
    )
}

export default ContentBlock;