import React, {Component} from 'react';

import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import {Spin, Layout, Menu} from 'antd';
import s from './App.module.scss';
import FirebaseContext from "./context/firebaseContext";
import {BrowserRouter, Link, Route, Switch, Redirect} from 'react-router-dom';
import {PrivateRoute} from "./utils/privateRoute";
import AboutPage from "./pages/About";

const {Header, Content} = Layout;

class App extends Component {
    state = {
        user: null
    }

    componentDidMount() {
        const {auth, setUserUid} = this.context;

        auth.onAuthStateChanged((user) => {
            if (user) {
                setUserUid(user.uid);
                localStorage.setItem('user', JSON.stringify(user.uid))
                this.setState({
                    user
                });
            } else {
                setUserUid(null);
                localStorage.removeItem('user')
                this.setState({
                    user: false
                });
            }
        })
    }

    render() {
        const {user} = this.state;

        if (user === null) {
            return (
                <div className={s.load_page}>
                    <Spin size="large"/>
                </div>
            );
        }

        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={LoginPage}/>

                    <Route render={() => (
                        <>
                            <Layout>
                                <Header>
                                    <Menu theme="dark" mode="horizontal">
                                        <Menu.Item key="1">
                                            <Link to="/">Home</Link>
                                        </Menu.Item>
                                        <Menu.Item key="2">
                                            <Link to="/about">About</Link>
                                        </Menu.Item>
                                    </Menu>
                                </Header>
                                <Content>
                                    <Switch>
                                        <PrivateRoute path="/" exact component={HomePage}/>
                                        <PrivateRoute path="/home" component={HomePage}/>
                                        <PrivateRoute path="/about" component={AboutPage}/>
                                        <Redirect to="/"/>
                                    </Switch>
                                </Content>
                            </Layout>

                        </>
                    )}/>
                </Switch>

            </BrowserRouter>
        )
    }
}

App.contextType = FirebaseContext;

export default App;
