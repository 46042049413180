import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export const PrivateRoute = ({component: Component, user, ...rest}) => (
    <Route
        {...rest}
        render={props => localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to="/login"/>
        }
    />
)