import React, {Component} from "react";

import s from './Login.module.scss';
import {Layout, Form, Input, Button, Alert, Typography} from 'antd';
import FirebaseContext from "../../context/firebaseContext";

const {Content} = Layout;
const {Title} = Typography;

class LoginPage extends Component {

    state = {
        errorMessage: null,
        formVisible: 'login'
    }

    onFinish = ({email, password}) => {
        const {formVisible} = this.state;
        const {signWithEmail, createUser, setUserUid} = this.context;
        const {history} = this.props;

        if (formVisible === 'login') {
            signWithEmail(email, password)
                .then(res => {
                    setUserUid(res.user.uid);
                    localStorage.setItem('user', JSON.stringify(res.user.uid))
                    history.push('/');
                })
                .catch(res => {
                    this.setState({errorMessage: res.message})
                })
        } else {
            createUser(email, password)
                .then(res => {
                    setUserUid(res.user.uid);
                    localStorage.setItem('user', JSON.stringify(res.user.uid))
                    history.push('/');
                })
                .catch(res => {
                    this.setState({errorMessage: res.message})
                })
        }
    }

    changeVisibleForm = () => {
        const NewFormVisible = this.state.formVisible === 'login' ? 'registraton' : 'login'

        this.setState(
            {formVisible: NewFormVisible}
        )
    }

    renderFailedMessage = () => {
        const {errorMessage} = this.state;

        if (errorMessage !== null) {
            return (
                <Alert style={{marginBottom: 24}} message={errorMessage} type="error"/>
            )
        }
    }

    renderFormLogin = () => {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };

        const {formVisible} = this.state;

        return (
            <>
                {this.renderFailedMessage()}

                <Title level={4}> {formVisible === 'login' ? 'Войти' : 'Зарегистрироваться'}</Title>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={this.onFinish}
                >

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Please input your email!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Please input your password!'}]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            {formVisible === 'login' ? 'Войти' : 'Зарегистрироваться'}
                        </Button>
                        <Button type="link" htmlType="button" onClick={this.changeVisibleForm}>
                            {formVisible === 'login' ? 'Зарегистрироваться' : 'Войти'}
                        </Button>
                    </Form.Item>
                </Form>
            </>

        );
    }


    render() {
        return (
            <Layout>
                <Content>
                    <div className={s.root}>
                        <div className={s.wrap}>
                            {this.renderFormLogin()}
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

LoginPage.contextType = FirebaseContext;

export default LoginPage;